import React from 'react';
import styled from "styled-components";
import Subscribed from "../../assets/icons/Subscribed";

const TrackingBreadcrumb = (props) => {
    const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        },
        atLaundromatDetails = props.details[ props.details.length - 2 ],
        laundromatDate = atLaundromatDetails?.statusUpdatedAt,
        atLaundromatDate = new Date( laundromatDate );

    let dates = [];
    let washingDate = new Date( atLaundromatDate ),
        dryingDate = new Date( atLaundromatDate ),
        foldingDate = new Date( atLaundromatDate );

    if( laundromatDate && atLaundromatDate?.toString() !== 'Invalid Date') {
        washingDate.setHours( atLaundromatDate.getHours() + 2 );
        dryingDate.setHours( atLaundromatDate.getHours() + 4 );
        foldingDate.setHours( atLaundromatDate.getHours() + 6 );
    }

    for( let item of props.details ) {
        dates.push({
            "displayDate": (new Date(item.statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')
        });
    }

    return (
        <Container>
            <BubbleGroup>
                { props.currentlyActive <= 1 && <Bubble active={props.currentlyActive === 1}>1</Bubble>}
                { props.currentlyActive > 1 &&
                    <Checkmark>
                        <Subscribed />
                    </Checkmark>
                }
                {props.screenType === "Desktop" && "Picked Up"}
                {props.screenType !== "Desktop" &&
                    <Excerpt>
                        <Left>
                            Picked Up
                        </Left>
                        { props.details[0] &&
                            <Right>
                                {dates[0].displayDate}
                            </Right>
                        }
                    </Excerpt>
                }
            </BubbleGroup>
            <Separator active={ props.currentlyActive > 1 } />
            <BubbleGroup>
                { props.currentlyActive <= 2 && <Bubble active={props.currentlyActive === 2}>2</Bubble> }
                { props.currentlyActive > 2 &&
                    <Checkmark>
                        <Subscribed />
                    </Checkmark>
                }
                {props.screenType === "Desktop" && "At Laundromat"}
                {props.screenType !== "Desktop" &&
                    <Excerpt>
                        <Left>
                            At Laundromat
                        </Left>
                        { props.details[1] &&
                            <Right>
                                {dates[1].displayDate}
                            </Right>
                        }
                    </Excerpt>
                }
            </BubbleGroup>
            <Separator active={ props.currentlyActive > 2 }/>
            <BubbleGroup>
                { props.currentlyActive <= 3 && <Bubble active={props.currentlyActive === 3}>3</Bubble> }
                { props.currentlyActive > 3 &&
                    <Checkmark>
                        <Subscribed />
                    </Checkmark>
                }
                {props.screenType === "Desktop" && "Washing"}
                {props.screenType !== "Desktop" &&
                    <Excerpt>
                        <Left>
                            Washing
                        </Left>
                        { washingDate && washingDate?.toString() !== 'Invalid Date' && 3 <= props.currentlyActive &&
                            <Right>
                                {washingDate.toLocaleString('en-US', options).replace('at', '')}
                            </Right>
                        }
                    </Excerpt>
                }
            </BubbleGroup>
            <Separator active={ props.currentlyActive > 3 }/>
            <BubbleGroup>
                { props.currentlyActive <= 4 && <Bubble active={props.currentlyActive === 4}>4</Bubble> }
                { props.currentlyActive > 4 &&
                    <Checkmark>
                        <Subscribed />
                    </Checkmark>
                }
                {props.screenType === "Desktop" && "Drying"}
                {props.screenType !== "Desktop" &&
                    <Excerpt>
                        <Left>
                            Drying
                        </Left>
                        { dryingDate && dryingDate?.toString() !== 'Invalid Date' && 4 <= props.currentlyActive &&
                            <Right>
                                {dryingDate.toLocaleString('en-US', options).replace('at', '')}
                            </Right>
                        }
                    </Excerpt>
                }
            </BubbleGroup>
            <Separator active={ props.currentlyActive > 4 }/>
            <BubbleGroup>
                { props.currentlyActive <= 5 && <Bubble active={props.currentlyActive === 5}>5</Bubble> }
                { props.currentlyActive > 5 &&
                    <Checkmark>
                        <Subscribed />
                    </Checkmark>
                }
                {props.screenType === "Desktop" && "Folding"}
                {props.screenType !== "Desktop" &&
                    <Excerpt>
                        <Left>
                            Folding
                        </Left>
                        { foldingDate && foldingDate?.toString() !== 'Invalid Date' && 5 <= props.currentlyActive &&
                            <Right>
                                {foldingDate.toLocaleString('en-US', options).replace('at', '')}
                            </Right>
                        }
                    </Excerpt>
                }
            </BubbleGroup>
            <Separator active={ props.currentlyActive > 5 }/>
            <BubbleGroup>
                { props.currentlyActive <= 6 && <Bubble active={props.currentlyActive === 6}>6</Bubble> }
                { props.currentlyActive > 6 &&
                    <Checkmark>
                        <Subscribed />
                    </Checkmark>
                }
                {props.screenType === "Desktop" && "Outbound"}
                {props.screenType !== "Desktop" &&
                    <Excerpt>
                        <Left>
                            Outbound
                        </Left>
                        { props.details[2] &&
                            <Right>
                                {dates[2].displayDate}
                            </Right>
                        }
                    </Excerpt>
                }
            </BubbleGroup>
            <Separator active={ props.currentlyActive > 6 }/>
            <BubbleGroup>
                { props.currentlyActive <= 7 && <Bubble active={props.currentlyActive === 7}>7</Bubble> }
                { props.currentlyActive > 7 &&
                    <Checkmark>
                        <Subscribed />
                    </Checkmark>
                }
                {props.screenType === "Desktop" && "Delivered"}
                {props.screenType !== "Desktop" &&
                    <Excerpt>
                        <Left>
                            Delivered
                        </Left>
                        { props.details[3] &&
                            <Right>
                                {dates[3].displayDate}
                            </Right>
                        }
                    </Excerpt>
                }
            </BubbleGroup>
        </Container>
    );
};

export default TrackingBreadcrumb;

const Excerpt = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const Left = styled.div`
    font-weight: 700;
    width: 100%;
    text-align: center;
`;
const Right = styled.div`
    width: 100%;
    text-align: center;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 15px 0;

    @media (max-width: 1200px) {
        padding: 15px 50px;
        margin-top: 10px;
        flex-direction: column;
    }

    @media (max-width: 768px) {
        padding: 0 15px;
        margin: 0;
    }
`;

const BubbleGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    @media (max-width: 1200px) {
        flex-direction: row;
        padding: 20px 40px;
        width: 100%;
        justify-content: flex-start;
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
    }
`;

const Bubble = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${props => props.active ? "#030303" : "#d3d3d3"};
    border-radius: 9999px;
    color: ${props => props.active ? "#ffffff" : "#030303"};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
        margin-right: 15px;
    }
`;

const Separator = styled.div`
    flex-grow: 1;
    height: 4px;
    background-color: ${props => props.active ? "#030303" : "#d3d3d3"};
    margin: 0 10px;

    @media (max-width: 1200px) {
        background-color: #d3d3d3 !important;
        width: 100%;
        height: 2px;
    }
`;

const Checkmark = styled.div`
    & svg {
        margin-right: 15px;
        width: 40px;
        height: 40px;
        fill: #4cd964;
        color: #4cd964;

        & svg {
            fill: #ffffff;
            color: #ffffff;
        }
    }
`;
