import styled from "styled-components";

export const Paragraph = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: ${props => props.align || "center"};
    margin: 10px 0;
    display: flex;
    justify-content: center;
    
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 13px;
    }
`;

export const PasswordText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 13px;
    }
`;

export const Text = styled.div`
    width: 430px;
    
    @media (max-width: 768px) {
        width: 90%;
    }
`;
