import React from "react";
import SVG from "./SVG";

const Socks = (props) => {
    return (
        <SVG viewBox="0 0 40 40" width="40" height="40" fill="none">
            <svg viewBox="0 0 576 512" width="40" height="40" fill="#030303" x="0"
                 y="0" opacity="100%">
                <path
                    d="M319.1 32c0-11 3.125-21.25 8-30.38C325.4 .8721 322.9 0 319.1 0H192C174.4 0 159.1 14.38 159.1 32l.0042 32h160L319.1 32zM246.6 310.1l73.36-55l.0026-159.1h-160l-.0042 175.1l-86.64 64.61c-39.38 29.5-53.86 84.4-29.24 127c18.25 31.62 51.1 48.36 83.97 48.36c20 0 40.26-6.225 57.51-19.22l21.87-16.38C177.6 421 193.9 350.6 246.6 310.1zM351.1 271.1l-86.13 64.61c-39.37 29.5-53.86 84.4-29.23 127C254.9 495.3 287.2 512 320.1 512c20 0 40.25-6.25 57.5-19.25l115.2-86.38C525 382.3 544 344.2 544 303.1v-207.1h-192L351.1 271.1zM512 0h-128c-17.62 0-32 14.38-32 32l-.0003 32H544V32C544 14.38 529.6 0 512 0z"></path>
            </svg>
            <defs>
                <filter id="filter_dshadow_10_0_2_0000001a" color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="bg-fix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="alpha"></feColorMatrix>
                    <feOffset dx="0" dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="5"></feGaussianBlur>
                    <feComposite in2="alpha" operator="out"></feComposite>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_10_0_2_0000001a"></feBlend>
                    <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_10_0_2_0000001a" result="shape"></feBlend>
                </filter>
            </defs>
        </SVG>    )
}

export default Socks;
