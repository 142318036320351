import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components"
import Logo from "../../assets/images/logos/headerLogo.png"
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Logout from "../Auth/Logout/v1/Logout";
import Aux from "../Aux/Aux";
import Spinner from "../Spinner/v1/Spinner";

const DefaultHeader = (props) => {
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const [hamburgerOpen, setHamburgerOpen ] = useState(false);
    const hamburgerRef = useRef();
    const hamburgerLabelRef = useRef();
    const navbarRef = useRef();
    const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated );
    const role = useSelector( (state) => state.auth.role )?.toLowerCase();
    const firstName = useSelector( (state) => state.customer.firstname );
    const lastName = useSelector( (state) => state.customer.lastname );
    const [loading, isLoading] = useState(false);

    const handleLogoClick = () => {
        navigate("/");
    }

    useEffect(() => {
        const handleResize = () => {
            setHamburgerOpen( false );
        };

        const handleClick = (event) => {
            if( hamburgerOpen && !navbarRef.current?.contains( event.target ) && !hamburgerRef.current?.contains( event.target ) && !hamburgerLabelRef.current?.contains( event.target) ) {
                setHamburgerOpen( false );
                hamburgerRef.current.click();
            }
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleClick);
        document.addEventListener('click', handleClick);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleClick);
            document.removeEventListener('click', handleClick);
        }
    }, [hamburgerOpen]);

    const handleHamburgerToggle = () => {
        setHamburgerOpen( hamburgerRef.current ? hamburgerRef.current.checked : !hamburgerOpen );
    }

    const handleNavItemClick = (event) => {
        hamburgerRef.current.click()
        navigate( event.target.getAttribute("data-uri") );
    }

    return (
        <Aux>
            { loading && <Spinner />}
            <NavBar
                background={props.background}
            >
                {/*<ImageLeft src={Logo}  alt={"Logo"} onClick={handleLogoClick}/>*/}
                <TempLogo onClick={handleLogoClick}>The Origami Company</TempLogo>
                <HamburgerMenu ref={hamburgerLabelRef}>
                    <HamburgerInput ref={hamburgerRef} onClick={handleHamburgerToggle} type={"checkbox"} />
                </HamburgerMenu>
                <NavDiv ref={navbarRef} className={hamburgerOpen ? "nav-open" : "'"}>
                    <Link className={pathname === "/about" ? "active" : ""} onClick={handleNavItemClick} data-uri={"/about"}>About</Link>
                    <Link className={pathname === "/faq" ? "active" : ""} onClick={handleNavItemClick} data-uri={"/faq"}>FAQ</Link>
                    <Link className={pathname === "/contact-us" ? "active" : ""} onClick={handleNavItemClick} data-uri={"/contact-us"}>Contact</Link>
                    {!props.isLogin && !isAuthenticated && <Link className={pathname === "/login" ? "active" : ""} onClick={handleNavItemClick} data-uri={"/login"}>Log In</Link> }
                    {isAuthenticated && <Link className={pathname === "/" + role ? "active" : ""} onClick={handleNavItemClick} data-uri={"/" + role}>Dashboard</Link> }
                    {isAuthenticated && <HeaderButton><Logout isLoading={isLoading} /></HeaderButton> }
                </NavDiv>
            </NavBar>
            { isAuthenticated && firstName && lastName && <WelcomeBar><Name>Welcome {firstName} {lastName}</Name></WelcomeBar>}
        </Aux>
    )
}

export default DefaultHeader;

const HeaderButton = styled.div`
    display: inline;
    
    @media (max-width: 768px) {
        & div {
            background-color: transparent !important;
            color: #030303 !important;
        }
    }
`;

const WelcomeBar = styled.div`
    width: 100%;
    height: 41px;
    background-color: #f3f3f3;
    position: relative;
`;

const Name = styled.div`
    color: #030303;
    font-size: 20px;
    line-height: 19px;
    text-align: right;
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    
    @media (max-width: 768px) {
        right: 10px;
        font-size: 15px;
        line-height: 15px;
    }
`;

const TempLogo = styled.h3`
    cursor: pointer;
    margin-left: 50px;
    
    @media (max-width: 768px) {
        margin-left: 20px;
        font-size: 14px;
        flex-grow: 1;
    }
`;

const HamburgerMenu = styled.label`
    position: absolute;
    cursor: pointer;
    width: max-content;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    z-index: 1000;

    &:before, &:after, & input {
        content: "";
        width: var(--navbar-bar-width);
        height: var(--navbar-bar-height);
        background-color: var(--nav-background);
        border-radius: 9999px;
        transition: all var(--navbar-animation-timing);
        transform-origin: right center;
    }
    
    &:has(input:checked):before {
        rotate: -45deg;
        transform: translateY(calc((var(--navbar-bar-height) + var(--hamburger-gap) - 2px) / -2));
    }

    &:has(input:checked):after {
        rotate: 45deg;
        transform: translateY(calc((var(--navbar-bar-height) + var(--hamburger-gap) - 2px) / 2));
    }
    
    &:has(input:checked):before, &:has(input:checked):after {
        width: var(--x-width);
    }
    
    & input:checked {
        opacity: 0;
        width: 0;
    }

    @media screen and (min-width: 850px) {
        display: none;
    }
`;

const HamburgerInput = styled.input`
    appearance: none;
    margin: 0;
    padding: 0;
    outline: none;
    pointer-events: none;
`;

const NavBar = styled.div`
    display: flex;
    width: 100%;
    height: 70px;
    background-color: ${props => props.background || "transparent"};
    position: relative;
    align-items: center;
`;

const Link = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    &:hover {
        border-bottom: none;
        background-color: #92929240;
    }

    &.active {
        background-color: #030303;
        color: white;
        
        @media screen and (min-width: 850px) {
            background-color: transparent;
            color: #030303;
            border-bottom: 3px solid #030303;

            &:hover {
                border-bottom: 3px solid #ffffff;
                background-color: transparent;
            }
        }
    }

    @media screen and (min-width: 850px) {
        display: inline;

        &:hover {
            border-bottom: 3px solid #929292;
        }
    }
`;

const ImageLeft = styled.img`
    width: 110px;
    cursor: pointer;
    margin-left: 10px;
`;

const NavDiv = styled.aside`
    position: absolute;
    transition: all var(--navbar-animation-timing);
    z-index: 10000;

    @media screen and (max-width: 849px) {
        opacity: 0;
        visibility: hidden;
        background-color: white;
        width: 100%;
        text-align: center;
        top: 70px;

        &.nav-open {
            visibility: visible;
            opacity: 1;
        }
    }

    @media screen and (min-width: 850px) {
        top: 50%;
        right: 50px;
        transform: translateY(-50%);
    }
`;
