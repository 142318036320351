import React, {useRef, useState} from "react";
import styled from "styled-components"
import FormContainer from "../Form/Container/v1/FormContainer";
import Button from "../Form/Button/v1/Button";
import Input from "../Form/Input/v1/Input";
import PageContainer from "../utility/containers/PageContainer";
import PageTitle from "../utility/text/PageTitle";
import PageSubheader from "../utility/text/PageSubheader";
import {Link} from "react-router-dom";
import Profile from "../../assets/icons/Profile";
import Mail from "../../assets/icons/Mail";
import Phone from "../../assets/icons/Phone";
import Aux from "../Aux/Aux";
import PageWidthContainer from "../utility/containers/PageWidthContainer";
import Image from "../Image/v1/Image";
import SignupImg from "../../assets/images/background/Signup_page.png";
import TeaserCard from "../utility/containers/TeaserCard";
import {Paragraph, Text} from "../utility/helper/Paragraph";
import {useSelector} from "react-redux";

const SignupInfo = (props) => {
    const checkboxRef = useRef();
    const usernameError = useSelector( (state) => state.signup.usernameError );
    const [formError, setFormError] = useState({
        firstname: false,
        lastname: false,
        emailAddress: false,
        phoneNumber: false,
        consentCheckbox: false,
    });
    const validationRegex = {
        firstname: /^[A-Za-zÀ-ÖØ-öø-ÿ'’\-\s]+$/,
        lastname: /^[A-Za-zÀ-ÖØ-öø-ÿ'’\-\s]+$/,
        emailAddress: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        phoneNumber: /^\+?[1-9][\d-]{1,14}$/,
    };

    const hasErrors = () => {
        let keys = Object.keys( formError );
        let error = false;
        for( let key of keys ) {
            error = error || formError[ key ];
            if( key === "consentCheckbox" ) {
                if( !checkboxRef?.current?.querySelector('input[type="checkbox"]')?.checked ) {
                    error = true;
                    setError( key );
                }
            }
            else if( !props.formData[ key ] || ( validationRegex[key] && !validationRegex[ key ]?.test( props.formData[ key ]) ) ) {
                error = true;
                setError( key );
            }
        }
        return error;
    }

    const setError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: true
        }));
    }

    const clearError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: false
        }));
    }

    return (
        <Aux>
            { (props.screenType === "Desktop" || props.screenType === "Mobile") &&
                <Aux>
                    <PageTitle alignment={"center"}>Create an account</PageTitle>
                    <PageSubheader alignment={"center"}>To get started, enter your personal details</PageSubheader>
                </Aux>
            }
            { props.screenType === "Tablet" &&
                <PageWidthContainer
                    width={100}
                    height={"333px"}
                    display={true}
                >
                    <Image
                        cssUrl={SignupImg}
                        height={"333px"}
                    />
                    <TeaserCard>
                        <PageTitle margin={"none"} alignment={"center"}>Create an account</PageTitle>
                        <PageSubheader alignment={"center"}>To get started, enter your personal details</PageSubheader>
                    </TeaserCard>
                </PageWidthContainer>
            }
            <PageContainer>
                <FormContainer errorMessage={usernameError}>
                    <Input
                        name={"firstname"}
                        type={"text"}
                        regex={validationRegex.firstname}
                        labelText={"First Name"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"John"}
                        hasError={formError.firstname}
                        value={props.formData.firstname}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please enter a valid First Name"}
                    >
                        <Profile />
                    </Input>
                    <Input
                        name={"lastname"}
                        type={"text"}
                        regex={validationRegex.lastname}
                        labelText={"Last Name"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"Doe"}
                        hasError={formError.lastname}
                        value={props.formData.lastname}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please enter a valid Last Name"}
                    >
                        <Profile />
                    </Input>
                    <Input
                        name={"emailAddress"}
                        type={"text"}
                        regex={validationRegex.emailAddress}
                        labelText={"Email Address"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"john.doe@email.com"}
                        hasError={formError.emailAddress}
                        value={props.formData.emailAddress}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please enter a valid email address"}
                    >
                        <Mail />
                    </Input>
                    <Input
                        name={"phoneNumber"}
                        type={"tel"}
                        regex={validationRegex.phoneNumber}
                        labelText={"Phone Number"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"123-456-7890"}
                        hasError={formError.phoneNumber}
                        value={props.formData.phoneNumber}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please enter a valid Phone Number"}
                    >
                        <Phone />
                    </Input>
                    <div ref={checkboxRef}>
                        <Input
                            name={"consentCheckbox"}
                            type={"checkbox"}
                            regex={validationRegex.consentCheckbox}
                            labelText={"I agree to receive updates from The Origami Company via SMS and email, including marketing messages. Message and data rates may apply."}
                            required={true}
                            screenType={props.screenType}
                            hasError={formError.consentCheckbox}
                            value={props.formData.consentCheckbox}
                            setError={setError}
                            clearError={clearError}
                            onChange={props.handleChange}
                            errorMessage={"You must consent in order to continue"}
                        />
                    </div>
                    <Paragraph align={"left"}><Text>By clicking Get Started, you agree to our Terms and Conditions, confirm you have read our Policy Privacy Notice.</Text></Paragraph>
                    <Button
                        id={"submit"}
                        hasErrors={hasErrors}
                        submit={props.handleNext}
                    >
                        Get Started
                    </Button>
                </FormContainer>
                <Paragraph><Text>Already have an account? <Link to={"/login"}><MyLink>Login</MyLink></Link></Text></Paragraph>
            </PageContainer>
        </Aux>
    )
}

export default SignupInfo;

const MyLink = styled.div`
    display: inline;
    color: #007aff;
`;