import React from "react";
import styled from "styled-components"
import {useNavigate} from "react-router-dom";
import Facebook from "../../assets/icons/Facebook";
import Twitter from "../../assets/icons/Twitter";
import Instagram from "../../assets/icons/Instagram";

const DefaultFooter = (props) => {
    const navigate = useNavigate();

    const handleNavItemClick = (event) => {
        navigate( event.target.getAttribute("data-uri") );
    }

    return (
        <Footer
            background={props.background}
            isShortContent={props.isShortContent}
        >
            <FooterLinks>
                <Link onClick={handleNavItemClick} data-uri={"/privacy-policy"}>Privacy Policy</Link>
                <Link onClick={handleNavItemClick} data-uri={"/terms-of-use"}>Terms of Service</Link>
                <Link onClick={handleNavItemClick} data-uri={"/contact-us"}>Contact Us</Link>
            </FooterLinks>
            <FooterLinks>
                <Link><Facebook /></Link>
                <Link><Twitter /></Link>
                <Link><Instagram /></Link>
            </FooterLinks>
        </Footer>
    )
}

export default DefaultFooter;

const Footer = styled.div`
    width: 100%;
    min-height: 100px;
    background-color: #f3f3f3;
    position: ${props => props.isShortContent ? "absolute" : "relative"};
    bottom: 0;
    padding: 15px;
`;

const Link = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    
    @media (max-width: 768px) {
        font-size: 13px;
        line-height: 13px;
    }
`;

const FooterLinks = styled.div`
    display: flex;
    justify-content: center;
`;