import React from "react";
import SVG from "./SVG";

const Laundromat = (props) => {
    return (
        <SVG viewBox="0 0 31 29" width="31" height="29" fill="none">
            <svg height="29" width="29" viewBox="0 0 24 24" fill="#030303" x="1"
                 y="0" opacity="100%">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"></path>
            </svg>
            <defs>
                <filter id="filter_dshadow_10_0_2_0000001a" color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="bg-fix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="alpha"></feColorMatrix>
                    <feOffset dx="0" dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="5"></feGaussianBlur>
                    <feComposite in2="alpha" operator="out"></feComposite>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_10_0_2_0000001a"></feBlend>
                    <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_10_0_2_0000001a" result="shape"></feBlend>
                </filter>
            </defs>
        </SVG>
    )
}

export default Laundromat;
