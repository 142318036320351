import React, {useState} from "react";
import styled from "styled-components"
import Input from "../../../Form/Input/v1/Input";
import FormContainer from "../../../Form/Container/v1/FormContainer";
import Button from "../../../Form/Button/v1/Button";
import {useDispatch, useSelector} from "react-redux";
import {handleLogin} from "../../../../store/reducers/auth";
import Aux from "../../../Aux/Aux";
import DefaultHeader from "../../../Headers/DefaultHeader";
import {Card} from "../../../utility/containers/Card";
import Profile from "../../../../assets/icons/Profile";
import Key from "../../../../assets/icons/Key";
import {useNavigate} from "react-router-dom";
import PageContainer from "../../../utility/containers/PageContainer";
import PageTitle from "../../../utility/text/PageTitle";
import PageWidthContainer from "../../../utility/containers/PageWidthContainer";
import Image from "../../../Image/v1/Image";
import LoginImg from "../../../../assets/images/background/Login.png";
import TeaserCard from "../../../utility/containers/TeaserCard";
import PageSubheader from "../../../utility/text/PageSubheader";

const Authentication = (props) => {
    const error = useSelector( (state) => state.auth.error );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [formError, setFormError] = useState({
        username: false,
        password: false,
    });

    const handleChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleSubmit = () => {
        const loginAPICall = async () => {
            await dispatch( handleLogin({
                "body": {
                    "username": formData.username,
                    "password": formData.password
                },
                "setLoading": props.isLoading,
            } ) );
        }

        props.isLoading(true);
        setTimeout(function() {
            loginAPICall();
        }, 10);
    }

    const handleSignup = () => {
        navigate( "/signup" );
    }

    const hasErrors = () => {
        if( !formData.username ) {
            setError( "username" );
        }
        if( !formData.password ) {
            setError( "password" );
        }

        return ( !formData.username || !formData.password ) || formError.username || formError.password;
    }

    const setError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: true
        }));
    }

    const clearError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: false
        }));
    }

    return (
        <Aux>
            <WhiteBackground>
                <DefaultHeader isLogin={true} />
            </WhiteBackground>
            { props.screenType === "Tablet" &&
                <PageWidthContainer
                    width={100}
                    height={"333px"}
                    display={true}
                >
                    <Image
                        cssUrl={LoginImg}
                        height={"333px"}
                    />
                    <TeaserCard>
                        <PageTitle margin={"none"} alignment={"center"}>Login</PageTitle>
                        <PageSubheader alignment={"center"}>View your laundry journey</PageSubheader>
                    </TeaserCard>
                </PageWidthContainer>
            }
            <PageContainer>
                {props.screenType === "Desktop" &&
                    <AbsoluteImage>
                        <Image
                            cssUrl={LoginImg}
                            width={"100%"}
                            height={"100%"}
                        />
                    </AbsoluteImage>
                }
                <CenterCard>
                    <Card>
                        { props.screenType !== "Tablet" && <PageTitle alignment={"center"}>Login</PageTitle> }
                        <FormContainer errorMessage={error}>
                            <Input
                                name={"username"}
                                type={"text"}
                                labelText={"Email or Phone"}
                                required={true}
                                screenType={props.screenType}
                                hasError={formError.username}
                                value={formData.useranme}
                                setError={setError}
                                clearError={clearError}
                                onChange={handleChange}
                                errorMessage={"Please enter a valid email or phone"}
                            >
                                <Profile />
                            </Input>
                            <Input
                                name={"password"}
                                type={"password"}
                                labelText={"Password"}
                                required={true}
                                screenType={props.screenType}
                                hasError={formError.password}
                                value={formData.password}
                                setError={setError}
                                clearError={clearError}
                                onChange={handleChange}
                                errorMessage={"Please make sure you follow our password requirements"}
                            >
                                <Key />
                            </Input>
                            <Button
                                id={"submit"}
                                hasErrors={hasErrors}
                                submit={handleSubmit}
                            >
                                Login
                            </Button>
                            <DividerParent>
                                <DividerLine />
                                <DividerText>OR</DividerText>
                                <DividerLine />
                            </DividerParent>
                            <Button
                                submit={handleSignup}
                                inverse={true}
                            >
                                Signup
                            </Button>
                        </FormContainer>
                    </Card>
                </CenterCard>
            </PageContainer>
        </Aux>
    )
}

export default Authentication;

const WhiteBackground = styled.div`
    background-color: white;
`;

const AbsoluteImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100%;
    height: 100%;
`;

const DividerParent = styled.div`
    width: 100%;
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-direction: row;
`;

const DividerText = styled.div`
    width: 33%;
    color: #030303;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
`;

const DividerLine = styled.div`
    width: 33%;
    height: 1px;
    background-color: #d3d3d3;
    border-radius: 2px;
`;

const CenterCard = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > div {
        padding: 20px 0;
    }
    
    @media (max-width: 768px) {
        & > div {
            padding: 0;
            display: block;
        }
    }

    @media (max-width: 1200px) {
        & > div {
            background-color: transparent;
            width: 100%;
            box-shadow: none;
            padding: 0;
        }
    }
`;
