import React, {useState} from 'react';
import { QrReader } from 'react-qr-reader';
import styled from "styled-components";
import Button from "../../Form/Button/v1/Button";
import Aux from "../../Aux/Aux";
import Cancel from "../../../assets/icons/Cancel";
import MessageContainer from "../../utility/MessageContainer/container";
import ErrorMessage from "../../utility/errorMessages/errors";

const QRCodeScanner = (props) => {
    const [isScanning, setIsScanning] = useState(false);
    const [scanError, setScanError] = useState(false);
    const constraints = {
        facingMode: "environment"
    }

    const toggleScanning = () => {
        if( isScanning ) {
            stopCamera();
            document.body.style.overflow = '';
        }
        else {
            document.body.style.overflow = 'hidden';
        }
        setIsScanning(!isScanning);
    };

    const stopCamera = () => {
        let video = document.getElementById("video");
        if( video != null && video.srcObject) {
            let mediaStream = video.srcObject;
            mediaStream?.getTracks()?.forEach(track => track.stop());
            video.srcObject = null;
        }
    };

    const handleScan = (data) => {
        if (data && data.length === 5) {
            setScanError( false )
            toggleScanning();
            props.handleScanResult( data );
        }
        else {
            setScanError( true )
        }
    };

    const handleError = (err) => {
        console.log(err);
    };

    return (
        <Aux>
            {!isScanning && <Button submit={toggleScanning}>Scan QR Code</Button>}
            {isScanning &&
                <Aux>
                    <CancelStyles onClick={toggleScanning}>
                        <Cancel />
                    </CancelStyles>
                    <QrStyles>
                        <QrReader
                            onResult={(result, error) => {
                                if (!!result) {
                                    handleScan(result.text);
                                }

                                if (!!error) {
                                    handleError(error);
                                }
                            }}
                            constraints={constraints}
                        />
                    </QrStyles>
                    {scanError &&
                        <ErrorContainer>
                            <MessageContainer>
                                <ErrorMessage>
                                    Please Scan your hamper's QR Code
                                </ErrorMessage>
                            </MessageContainer>
                        </ErrorContainer>
                    }
                </Aux>
            }
        </Aux>
    );
};

export default QRCodeScanner;

const CancelStyles = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000001;
    cursor: pointer;
`;

const ErrorContainer = styled.div`
    position: fixed;
    bottom: 50px;
    left: 50%;
    z-index: 1000001;
    transform: translateX(-50%);
    width: 430px;
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const QrStyles = styled.div`
    & section {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #030303f0;
        z-index: 100000;

        & div {
            width: 100%;
            height: 100%;
            padding: 0 !important;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            & video {
                position: relative !important;

                &:before {
                    content: '';
                    width: 70%;
                    height: 70%;
                    background-color: transparent;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border: 1px solid #ffffff;
                }
            }
        }
    }

    @media (max-width: 768px) {
        & section div {
            width: 100%;
            height: 100%;
        }
    }
`;
