import React from "react";
import Aux from "../../components/Aux/Aux";
import PageTitle from "../../components/utility/text/PageTitle";
import PageContainer from "../../components/utility/containers/PageContainer";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import Calendar from "../../components/Calendar/Calendar";

const CompanyHome = (props) => {
    return (
        <Aux>
            <DefaultHeader />
            <PageContainer>
                <PageTitle>Company Home</PageTitle>
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    )
}

export default CompanyHome;
