import styled from "styled-components";

export const CardParent = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

export const Card = styled.div`
    width: 600px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 2px 0 10px rgba(3,3,3,0.1);
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    & > svg {
        width: 120px;
        height: 120px;
    }

    @media (max-width: 768px) {
        background-color: transparent;
        width: 100%;
        box-shadow: none;
    }
`;

export const CardSmall = styled.div`
    width: 90%;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 2px 0 10px rgba(3,3,3,0.1);
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ImageCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 70px;
        height: 70px;
    }
`;

export const CardHeader = styled.div`
    margin: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    width: 100%;
`;

export const CardTitle = styled.div`
    margin-top: 10px;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    line-height: 18px;
    text-align: center;
    width: 100%;
`;

export const CardDetails = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    width: 100%;
`;
