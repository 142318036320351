import React from 'react';
import {Route, Routes} from "react-router-dom";
import NotFound from "../../../pages/NotFound/v1/NotFound";
import styled from "styled-components";
import CompanyHome from "../../../pages/Company/CompanyHome";
import CompanySignUp from "../../../pages/Company/CompanySignup";
import {useSelector} from "react-redux";

const StaticRoute = (props) => {
    const signupSuccess = useSelector( (state) => state.signup.successMessage );

    if( signupSuccess ) {
        props.redirect("/customer");
    }

    return (
        <Page>
            <Routes>
                <Route path={"/"} exact element={ <CompanyHome isShortContent={props.isShortContent} /> } />
                <Route path={"/signup"} exact element={ <CompanySignUp screenType={props.screenType} isShortContent={props.isShortContent}/> } />
                <Route path={"/about"} exact element={ <NotFound isShortContent={props.isShortContent} /> } />
                <Route path={"/contact-us"} exact element={ <NotFound isShortContent={props.isShortContent} /> } />
                <Route path={"/*"} element={ <NotFound screenType={props.screenType} isShortContent={props.isShortContent} /> } />
            </Routes>
        </Page>
    );
};

export default StaticRoute;


const Page = styled.div`
    width: 100vw;
    height: 100%;
`;
